import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../../components/seo"

const GuitarsIndexPage = () => {
    const data = useStaticQuery(graphql`
    {
        allMdx(
            sort: {order: ASC, fields: frontmatter___model}
            filter: {frontmatter: {collections: {eq: "guitars"}}}
          ) {
            nodes {
                frontmatter {
                    model
                    slug
                    swatch  {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 75, width: 1200, aspectRatio: 1.78)
                      }
                    }
                }
            }
        }
    }
  `);

  const guitars = data.allMdx.nodes.map((x) => x.frontmatter);

  return (
    <Layout >
      <Seo title="Talman guitars" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br />guitars</h1>

        <section className="flex flex-col items-center">
          <ul className="">
              {guitars.map(x => (
                  <li className="flex text-2xl sm:text-3xl  lg:text-6xl  items-center mb-6">
                    <Link to={x.slug}>{x.model}</Link>
                    <Link to={x.slug} className="ml-6 border-none max-w-xs md:max-w-md lg:max-w-xl transform duration-300 transition-transform hover:scale-105"><GatsbyImage image={getImage(x.swatch)} alt={`${x.model} guitar`} className="h-full block pixelate" /></Link>
                  </li>
              ))}
          </ul>

          <h2 className="text-xl">more coming soon!</h2>
        </section>
      </div>
  
    </Layout>
  )
}



export default GuitarsIndexPage